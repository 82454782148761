import { useContext } from 'react';
import { Pie } from '@ant-design/charts';
import { PieConfig } from '@ant-design/charts/es';
import { Empty } from 'antd';
import { TssDataContext } from '../../contexts/TssDataContext';
import {
  BUILDING_COLOR,
  BUILDING_LABEL,
  GROWING_COLOR,
  GROWING_LABEL,
  LEADING_COLOR,
  LEADING_LABEL,
  SOLID_COLOR,
  SOLID_LABEL,
  STRONG_COLOR,
  STRONG_LABEL,
} from '../../common/constants';

export const TssPerformance = () => {
  const {
    programScore: { tSSGrowingCount, tSSBuildingCount, tSSSolidCount, tSSStrongCount, tSSLeadingCount },
  } = useContext(TssDataContext);

  if (
    tSSGrowingCount == null &&
    tSSBuildingCount == null &&
    tSSSolidCount == null &&
    tSSStrongCount == null &&
    tSSLeadingCount == null
  ) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  const data = [
    {
      label: GROWING_LABEL,
      value: tSSGrowingCount,
    },
    {
      label: BUILDING_LABEL,
      value: tSSBuildingCount,
    },
    {
      label: SOLID_LABEL,
      value: tSSSolidCount,
    },
    {
      label: STRONG_LABEL,
      value: tSSStrongCount,
    },
    {
      label: LEADING_LABEL,
      value: tSSLeadingCount,
    },
  ];
  const config: PieConfig = {
    label: {
      type: 'inner',
      offset: '50%',
      style: { textAlign: 'center' },
      autoRotate: false,

      content: function content(_ref) {
        var percent = _ref.percent;
        return percent === 0 ? '' : `${Math.round(percent * 100)}%`;
      },
    },
    angleField: 'value',
    colorField: 'label',
    data: data,
    legend: {
      position: 'top',
      flipPage: false,
    },
  };

  return (
    <Pie
      {...config}
      height={300}
      color={({ label }) => {
        switch (label) {
          case GROWING_LABEL:
            return GROWING_COLOR;
          case BUILDING_LABEL:
            return BUILDING_COLOR;
          case SOLID_LABEL:
            return SOLID_COLOR;
          case STRONG_LABEL:
            return STRONG_COLOR;
          case LEADING_LABEL:
            return LEADING_COLOR;
          default:
            return '';
        }
      }}
    />
  );
};
