import { Radio, RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Guideline.styles.scss';
import { HighLightTab } from './HighLightTab';
import { KPISearchTab } from './KpiSearchTab';

const options = [
  { label: 'Highlight', value: 'Highlight' },
  { label: 'KPI Search', value: 'KPI Search' },
];
export const GuidelineView = () => {
  const [Tab, setTab] = useState<string>('Highlight');

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setTab(value);
  };
  return (
    <>
      <Helmet>
        <title>Guideline</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container guideline-container">
          <Radio.Group
            options={options}
            onChange={onChange}
            value={Tab}
            optionType="button"
            buttonStyle="solid"
            style={{ float: 'right' }}
          />
          {Tab === 'Highlight' ? <HighLightTab /> : <KPISearchTab />}
        </section>
      </div>
    </>
  );
};
