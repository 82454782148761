import { Button, Col, DatePicker, Popover, Row } from 'antd';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../../common/constants';
import { TssDataContext, TssDataContextProvider } from '../../contexts/TssDataContext';
import { Helmet } from 'react-helmet';
import downloadFileFromBlob from '../../utils/downloadFileFromBlob';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TssStatus } from '../../charts/tssAnalysis/TssStatus';
import { TssPerformance } from '../../charts/tssAnalysis/TssPerformance';
import { DepartmentTssPerformance } from '../../charts/tssAnalysis/DepartmentTssPerformance';
import { EngagementTss } from '../../charts/tssAnalysis/TssDitribution';
import Title from 'antd/lib/typography/Title';
import { TssTable } from '../../charts/tssAnalysis/TssTable';
import { TssColumn } from '../../charts/tssAnalysis/TssColumn';
import { Card } from '../../components/Card';
import { ExportNPS, ExportTSS } from '../../apis/scoreClient';

interface ITSSPickerProps {
  dateId?: string;
}

const NpsFormula = <div>Total % of promoters – total % of detractors = net promoter score</div>;

const TSSDatePicker = ({ dateId }: ITSSPickerProps) => {
  const history = useHistory();
  const { programScore } = useContext(TssDataContext);
  const date = dateId || programScore?.dateId;
  const month = date ? moment(`${date}`, DATE_ID_FORMAT) : null;
  return (
    <DatePicker
      value={month}
      disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
      onChange={(e) => {
        e && history.push(`/relationship/tssdashboard?dateId=${e.format(DATE_ID_FORMAT)}`);
      }}
      format={MONTH_PICKER_FORMAT}
      picker="month"
      allowClear={false}
    />
  );
};

export const TssDashboard = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dateId = params.get('dateId');
  const month = dateId ? moment(dateId, DATE_ID_FORMAT) : null;

  const [isLoadingDownloadEngagement, setIsLoadingDownloadEngagement] = useState<boolean>(false);
  const [isLoadingDownloadTeam, setIsLoadingDownloadTeam] = useState<boolean>(false);

  const ExportData = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(true) : setIsLoadingDownloadTeam(true);
      const result = await ExportTSS(Number(dateId), type);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(false) : setIsLoadingDownloadTeam(false);
    }
  };

  return (
    <TssDataContextProvider month={month?.toDate()}>
      <Helmet>
        <title>Tss Analysis</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Program Overview</span>
            <TSSDatePicker dateId={dateId} />
          </div>
          <Row gutter={24} className="row fullwitdh-chart">
            <Col xs={24} sm={12} xxl={5} xl={6}>
              <Card title="Program TSS">
                <Popover title="The TSS Calculation" content={NpsFormula}>
                  <InfoCircleOutlined className="npsIcon" />
                </Popover>
                <TssStatus />
              </Card>
            </Col>
            <Col xs={24} sm={12} xxl={5} xl={8}>
              <Card title="TSS by Categories">
                <TssPerformance />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={10}>
              <Card title="Department TSS Performance">
                <DepartmentTssPerformance />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={12}>
              <Card title="Engagement TSS Distribution">
                <EngagementTss type="Engagement" />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={12}>
              <Card title="Team TSS Distribution">
                <EngagementTss type="Team" />
              </Card>
            </Col>
            <Col xxl={9} xl={12}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Engagement TSS Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Engagement')} loading={isLoadingDownloadEngagement}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <TssTable type="Engagement" />
              </Card>
            </Col>
            <Col xxl={8} xl={12}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Team TSS Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Team')} loading={isLoadingDownloadTeam}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <TssTable type="Team" />
              </Card>
            </Col>
            <Col xs={24} xxl={12}>
              <Card title="Engagement TSS">
                <div className="npsBarChar">
                  <TssColumn type="Engagement" />
                </div>
              </Card>
            </Col>
            <Col xs={24} xxl={12}>
              <Card title="Team TSS">
                <div className="npsBarChar">
                  <TssColumn type="Team" />
                </div>
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </TssDataContextProvider>
  );
};
