import { AuditedEntity } from './entity';

export interface NoteModel {
  teamId: number;
  description: string;
  dateId: number;
  id: number;
  parentId?: number;
}

export type NoteMode = 'create' | 'edit' | 'reply';

export const NoteTabKey = {
  teams: '1',
  all: '2',
};

export interface NoteResponse extends NoteModel, AuditedEntity {
  listChildNote: NoteResponse[];
  roles: string[];
  creationTime: string;
  teamName: string;
}

export interface NoteQuery {
  teamIds: string[] | number[];
  dateId: string | number;
}

export enum ChartType {
  ProjectMetricScoreFlowTrends = 1,
  ProjectMetricActualFlowTrends = 2,
}

export interface ChartNoteResponse extends AuditedEntity {
  teamId: number;
  note: string;
  chartType: ChartType;
  dateId: number;
  metricId?: number;
}
export interface ChartNoteRequest {
  id?: number;
  teamId: number;
  note: string;
  chartType: ChartType;
  dateId: number;
  metricId?: number;
}
