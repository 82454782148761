import { EngagementMonthlyScore, ScoreCardData, ScoreQuery, TeamMonthlyScore } from '../types/metrics';
import { ChartNoteRequest, ChartNoteResponse } from '../types/note';
import { npsDataByMonthResponse, npsDataResponse } from '../types/nps';
import { StatisticsResponse } from '../types/statistic';
import { tssDataByMonthResponse, tssDataResponse } from '../types/tss';
import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const fetchScores = (query: ScoreQuery): Promise<ScoreCardData[]> =>
  fetchAsync(`/api/score?${toUrlSearchParams(query)}`);

export const fetchNPSData = (query: ScoreQuery): Promise<npsDataResponse[]> =>
  fetchAsync(`/api/score/NPSData?${toUrlSearchParams(query)}`);

export const fetchTssData = (query: ScoreQuery): Promise<tssDataResponse[]> =>
  fetchAsync(`/api/tss/tssData?${toUrlSearchParams(query)}`);

export const fetchStatistics = (query: ScoreQuery): Promise<StatisticsResponse> =>
  fetchAsync(`/api/score/StatisticsScore?${toUrlSearchParams(query)}`);

export const fetchMonthlyScore = (query: ScoreQuery): Promise<EngagementMonthlyScore[]> =>
  fetchAsync(`/api/score/MonthlyScore?${toUrlSearchParams(query)}`);

export const fetchNPSDataByMonth = (): Promise<npsDataByMonthResponse[]> => fetchAsync(`/api/score/NPSDataByMonth`);

export const fetchTSSDataByMonth = (): Promise<tssDataByMonthResponse[]> => fetchAsync(`/api/tss/tssDataByMonth`);

export const ExportScore = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/score/ExportScore?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportNPS = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/score/ExportNps?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportTSS = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/tss/ExportTss?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsPSScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsPSScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsNPSScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsNPSScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const getChartNote = (teamId: number): Promise<ChartNoteResponse[]> => {
  let url = `/api/score/chartnote?teamId=${teamId}`;
  return fetchAsync(url, {
    method: 'GET',
  });
};

export const updateChartNote = (action: ChartNoteRequest): Promise<ChartNoteResponse> => {
  return fetchAsync(`/api/score/chartnote/${action.dateId}/${action.chartType}`, {
    method: 'PUT',
    body: action,
  });
};
