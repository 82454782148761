import { createContext, useContext } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { MeasureQuery, ScoreQuery } from '../types/metrics';
import { DATE_ID_FORMAT } from '../common/constants';
import { Spin } from 'antd';
import { TeamContext } from './TeamContext';
import { ITssData } from '../types/tss';
import { tssTransformData } from '../utils/tssData';
import { fetchTssData } from '../apis/scoreClient';

export const TssDataContext = createContext<ITssData>(null);

interface IProps {
  month: Date;
  children: React.ReactNode;
}

export const TssDataContextProvider = ({ month, children }: IProps) => {
  const { teams } = useContext(TeamContext);
  let endDateId = month ? moment(month).format(DATE_ID_FORMAT) : null;
  let measureQuery: MeasureQuery;
  const teamTypes = ['Program', 'Department'];

  if (month) {
    const startDateId = moment(month).clone().subtract(1, 'month').format(DATE_ID_FORMAT);
    measureQuery = {
      startDateId,
      endDateId,
    };
  } else {
    measureQuery = {
      monthsLimit: 2,
    };
  }

  const scoreQuery: ScoreQuery = {
    ...measureQuery,
    teamTypes: teamTypes,
  };

  const { data: tssData, loading, error } = useFetch(() => fetchTssData(scoreQuery), [month]);

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <Spin spinning={loading}>
      <TssDataContext.Provider value={tssTransformData(tssData, teams, endDateId)}>{children}</TssDataContext.Provider>
    </Spin>
  );
};
