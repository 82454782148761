import { Empty } from 'antd';
import { orderBy, minBy, maxBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import { DATE_ID_FORMAT } from '../../common/constants';
import { Line } from '@ant-design/charts';
import { LineConfig } from '@ant-design/charts/es';
import { IProgramTrendingData, KPIResponse } from '../../types/metrics';
import { useFetch } from '../../hooks/useFetch';
import { fetchKPI } from '../../apis/customMetric';

export interface IData {
  month: string;
  type: string;
  value: number;
}

interface IProps {
  projectId?: number;
  teamtype: string;
  type: string;
  categoryId: number;
}

export const PSFlowTrending = ({ teamtype, type, projectId, categoryId }: IProps) => {
  const { programDatas, teamDatas, engagementDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);
  const { data: KpiList } = useFetch(() => fetchKPI());
  const [KpiIsPublicList, setKpiIsPublicList] = useState<KPIResponse[]>();
  useEffect(() => {
    let KpiIsPublicList =
      teamtype === 'Program' ? KpiList?.filter((item) => item.isPublic === 1 && item.categoryId === categoryId) : [];
    setKpiIsPublicList(KpiIsPublicList);
  }, [categoryId, KpiList]);
  let plainData =
    teamtype === 'Program'
      ? programDatas
      : teamtype === 'Engagement'
      ? engagementDatas?.filter((item) => item.teamId === projectId)
      : teamDatas?.filter((item) => item.teamId === projectId);

  const data = orderBy(plainData, (item) => item.dateId).flatMap((item) => {
    const month = moment(item.dateId, DATE_ID_FORMAT).format('MMM YY');
    if (item.kpiData.length === 0) return [];
    else {
      var temp = [];
      KpiIsPublicList?.forEach((kpi) =>
        temp.push({
          month: month,
          type: kpi.name,
          value:
            type === 'Score'
              ? Number(item.kpiData?.find((x) => x.kpiName === kpi.name)?.score.toFixed(2))
              : Number(item.kpiData?.find((x) => x.kpiName === kpi.name)?.actual.toFixed(2)),
        })
      );
      return temp;
    }
  });

  if (data === undefined || data === null || data?.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    const config: LineConfig = {
      data: data,
      xField: 'month',
      yField: 'value',
      seriesField: 'type',
      point: {
        size: 3,
        shape: 'square',
      },
      label: {
        style: {
          fill: '#aaa',
        },
      },
      yAxis: {
        max: Math.max(maxBy(data, (item) => item.value)?.value + 0.05, 0.5),
        tickInterval: type === 'Score' ? 0.05 : 1,
        min: Math.min(minBy(data, (item) => item.value)?.value - 0.05, 0.5),
      },
      legend: {
        position: 'top',
      },
    };
    return <Line {...config} />;
  }
};
