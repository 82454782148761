import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es';
import { Empty } from 'antd';
import React, { useContext } from 'react';
import {
  DETRACTOR_LABEL,
  PASSIVE_LABEL,
  PROMOTER_LABEL,
  PROMOTER_COLOR,
  DETRACTOR_COLOR,
  PASSIVE_COLOR,
  GROWING_LABEL,
  BUILDING_LABEL,
  SOLID_LABEL,
  STRONG_LABEL,
  LEADING_LABEL,
  GROWING_COLOR,
  BUILDING_COLOR,
  SOLID_COLOR,
  STRONG_COLOR,
  LEADING_COLOR,
} from '../../common/constants';
import { TssDataContext } from '../../contexts/TssDataContext';

export const DepartmentTssPerformance = () => {
  const { departmentScores } = useContext(TssDataContext);
  if (!departmentScores?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  let tssData = [];

  departmentScores.forEach((item) => {
    let growingItem = { type: GROWING_LABEL, name: item.name, value: item.tSSGrowingCount };
    let buildingItem = { type: BUILDING_LABEL, name: item.name, value: item.tSSBuildingCount };
    let solidItem = { type: SOLID_LABEL, name: item.name, value: item.tSSSolidCount };
    let strongItem = { type: STRONG_LABEL, name: item.name, value: item.tSSStrongCount };
    let leadingItem = { type: LEADING_LABEL, name: item.name, value: item.tSSLeadingCount };

    tssData.push(growingItem, buildingItem, solidItem, strongItem, leadingItem);
  });

  var config: ColumnConfig = {
    data: tssData,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    height: 300,
    legend: {
      position: 'top',
      flipPage: false,
    },
    label: {
      content: (item) => {
        var percent = item.value;
        return percent === 0 ? '' : `${Math.round(percent * 100)}%`;
      },
      position: 'middle',
    },
    color: (_ref) => {
      var type = _ref.type;
      switch (type) {
        case GROWING_LABEL:
          return GROWING_COLOR;
        case BUILDING_LABEL:
          return BUILDING_COLOR;
        case SOLID_LABEL:
          return SOLID_COLOR;
        case STRONG_LABEL:
          return STRONG_COLOR;
        default:
          return LEADING_COLOR;
      }
    },
    yAxis: {
      label: {
        formatter: (_, item) => {
          return `${item.value * 100}%`;
        },
      },
    },
  };

  if (tssData.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <Column {...config} />;
};
