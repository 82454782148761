import { Button, Card, Col, Input, Row, Table } from 'antd';
import { Helmet } from 'react-helmet';
import { DatePicker } from 'antd';
import { DATE_ID_FORMAT, MIN_DATE_ID } from '../../common/constants';
import { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { StatisticScoreModel, StatisticsResponse, StatsQuery, StatsRequestExport } from '../../types/statistic';
import moment from 'moment';
import {
  fetchStatistics,
  fetchScores,
  ExportStatisticsScore,
  ExportStatisticsPSScore,
  ExportStatisticsNPSScore,
} from '../../apis/scoreClient';
import { Link, useHistory } from 'react-router-dom';
import { roundUpScore } from '../../utils/programData';
import Title from 'antd/lib/typography/Title';
import { useFetch } from '../../hooks/useFetch';
import { ScoreResponse } from '../../types/metrics';
import './ScoreStatistics.scss';
import { StyledLinkCell } from '../../charts/StyledLinkCell.styled';
import IconExternalLink from '../../icons/IconExternalLink';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import downloadFileFromBlob from '../../utils/downloadFileFromBlob';

const { RangePicker } = DatePicker;
const pageSize = 5;
const CustomCell = ({ value }: any) => {
  return (
    <div style={{ color: value < 0 ? 'red' : 'green' }}>
      {value == null ? '' : value < 0 ? `▼${value}%` : `▲${value}%`}
    </div>
  );
};

const MyCustomCell = (value: any) => <CustomCell value={value} />;

const NameCell = (text: string, record: StatisticScoreModel, projectLink = 'project') => (
  <StyledLinkCell>
    <Link target="_blank" to={`/${projectLink}/${record.teamId}`}>
      {text}
      <IconExternalLink />
    </Link>
  </StyledLinkCell>
);
const functionFilter = (selectedKeys, setSelectedKeys, confirm) => {
  return (
    <>
      <Input
        placeholder="Type search name"
        value={selectedKeys[0]}
        onChange={(data) => {
          setSelectedKeys(data.target.value ? [data.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {
          confirm();
        }}
        onBlur={() => {
          confirm();
        }}
      />
    </>
  );
};
const buildColumns = (projectLink?: string): ColumnType<StatisticScoreModel>[] => [
  {
    dataIndex: 'teamName',
    title: 'Name',
    ellipsis: true,
    width: 250,
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) =>
      functionFilter(selectedKeys, setSelectedKeys, confirm),
    // eslint-disable-next-line react/display-name
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) => {
      return record.teamName.toLowerCase().includes(value.toString().toLowerCase());
    },
    render: (text, record) => NameCell(text, record, projectLink),
  },
  {
    dataIndex: 'avgScore',
    align: 'center',
    title: 'Avg Score',
    sorter: (a, b) => a.avgScore - b.avgScore,
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 120,
  },
  {
    dataIndex: 'change',
    align: 'center',
    title: 'Change',
    render: MyCustomCell,
    sorter: (a, b) => a.change - b.change,
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 120,
  },
];

export const ScoreStatistics = () => {
  const { data: scoreData } = useFetch(() => fetchScores({ startDateId: '' }));
  function getValidMonth(noteData: ScoreResponse[]) {
    let validMonths = Array.from(new Set(noteData?.map((item: ScoreResponse) => item.dateId)));
    return validMonths;
  }
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const breakpoint = 415;

  const validMonths = getValidMonth(scoreData);
  const validMonth = validMonths?.sort().reverse()[0];
  const [endDateId, setEndDate] = useState(null);
  const [startDateId, setStartDate] = useState(null);

  const [engagementPage, setEngagementPage] = useState(1);
  const [PSPage, setPSPage] = useState(1);
  const [NPSPage, setNPSPage] = useState(1);
  const [teamPage, setTeamPage] = useState(1);
  const [PSTeamPage, setPSTeamPage] = useState(1);
  const [NPSTeamPage, setNPSTeamPage] = useState(1);

  const [engagementTableData, setEngagementTableData] = useState<StatisticScoreModel[]>(undefined);
  const [npsTableData, setNpsTableData] = useState<StatisticScoreModel[]>(undefined);
  const [psTableData, setPsTableData] = useState<StatisticScoreModel[]>(undefined);

  const [teamTableData, setTeamTableData] = useState<StatisticScoreModel[]>(undefined);
  const [npsTeamTableData, setNpsTeamTableData] = useState<StatisticScoreModel[]>(undefined);
  const [psTeamTableData, setPsTeamTableData] = useState<StatisticScoreModel[]>(undefined);
  const [isLoadingDownloadEngScore, setIsLoadingDownloadEngScore] = useState<boolean>(false);
  const [isLoadingDownloadTeamScore, setIsLoadingDownloadTeamScore] = useState<boolean>(false);
  const [isLoadingDownloadEngPS, setIsLoadingDownloadEngPS] = useState<boolean>(false);
  const [isLoadingDownloadTeamPS, setIsLoadingDownloadTeamPS] = useState<boolean>(false);
  const [isLoadingDownloadEngNPS, setIsLoadingDownloadEngNPS] = useState<boolean>(false);
  const [isLoadingDownloadTeamNPS, setIsLoadingDownloadTeamNPS] = useState<boolean>(false);
  const engagementColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (engagementPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns('engagement'),
  ];

  const psScoreColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (PSPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns('engagement'),
  ];
  const npsScoreColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (NPSPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns('engagement'),
  ];
  const teamColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (teamPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns(),
  ];

  const psScoreTeamColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (PSTeamPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns(),
  ];
  const npsScoreTeamColumns: ColumnType<StatisticScoreModel>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (NPSTeamPage - 1) * pageSize + 1,
      width: 60,
      align: 'center',
    },
    ...buildColumns(),
  ];
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  useEffect(() => {
    setEndDate(moment(validMonth, 'YYYYMM'));
    setStartDate(moment(validMonth, 'YYYYMM').subtract(2, 'months'));
  }, [validMonth]);

  const handleCalendarChange = ([startDateId, endDateId]) => {
    setStartDate(startDateId);
    setEndDate(endDateId);
  };

  let statisticRequestExport: StatsRequestExport = {
    teamType: 'Team',
    startDateId: parseInt(moment(startDateId).format(DATE_ID_FORMAT)),
    endDateId: parseInt(moment(endDateId).format(DATE_ID_FORMAT)),
  };

  const getStatisticData = async () => {
    const statisticQuery: StatsQuery = {
      startDateId: parseInt(moment(startDateId).format(DATE_ID_FORMAT)),
      endDateId: parseInt(moment(endDateId).format(DATE_ID_FORMAT)),
    };
    if (!isNaN(statisticQuery.startDateId) && !isNaN(endDateId)) {
      try {
        const statisticData = await fetchStatistics(statisticQuery);
        statisticData.teamScore && setTeamTableData(statisticData.teamScore);
        statisticData.teamNPSScore && setNpsTeamTableData(statisticData.teamNPSScore);
        statisticData.teamPSScore && setPsTeamTableData(statisticData.teamPSScore);

        statisticData.engagementScore && setEngagementTableData(statisticData.engagementScore);
        statisticData.engagementNPSScore && setNpsTableData(statisticData.engagementNPSScore);
        statisticData.engagementPSScore && setPsTableData(statisticData.engagementPSScore);
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    getStatisticData();
    // engagementTableData.forEach((element) => {});
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateId, endDateId]);

  const StatisticsScore = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngScore(true) : setIsLoadingDownloadTeamScore(true);
      statisticRequestExport.teamType = type;
      const result = await ExportStatisticsScore(statisticRequestExport);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngScore(false) : setIsLoadingDownloadTeamScore(false);
    }
  };

  const StatisticsPSScore = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngPS(true) : setIsLoadingDownloadTeamPS(true);
      statisticRequestExport.teamType = type;
      const result = await ExportStatisticsPSScore(statisticRequestExport);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngScore(false) : setIsLoadingDownloadTeamScore(false);
    }
  };

  const StatisticsNPSScore = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngNPS(true) : setIsLoadingDownloadTeamNPS(true);
      statisticRequestExport.teamType = type;
      const result = await ExportStatisticsNPSScore(statisticRequestExport);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngPS(false) : setIsLoadingDownloadTeamPS(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Statistic</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container" style={{ display: 'flex' }}>
            <span style={{ fontWeight: 750 }} className="section-title">
              Statistics
            </span>
            {parseInt(moment(startDateId).format(DATE_ID_FORMAT)) !== null && (
              <div className="edit-action-buttons">
                <RangePicker
                  allowClear={false}
                  id="date"
                  name="date"
                  disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
                  value={[startDateId, endDateId]}
                  picker="month"
                  onCalendarChange={handleCalendarChange}
                />
              </div>
            )}
          </div>
          <Row gutter={15} align="top" style={{ marginBottom: '20px' }}>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Project Score
                  </Title>
                  <Button onClick={() => StatisticsScore('Engagement')} loading={isLoadingDownloadEngScore}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (engagementPage) => setEngagementPage(engagementPage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={engagementColumns}
                  dataSource={roundUpScore(engagementTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Team Score
                  </Title>
                  <Button onClick={() => StatisticsScore('Team')} loading={isLoadingDownloadTeamScore}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (teamPage) => setTeamPage(teamPage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={teamColumns}
                  dataSource={roundUpScore(teamTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={15} align="top" style={{ marginBottom: '20px' }}>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Project PS Score
                  </Title>
                  <Button onClick={() => StatisticsPSScore('Engagement')} loading={isLoadingDownloadEngPS}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (PSpage) => setPSPage(PSpage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={psScoreColumns}
                  dataSource={roundUpScore(psTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Team PS Score
                  </Title>
                  <Button onClick={() => StatisticsPSScore('Team')} loading={isLoadingDownloadTeamPS}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (PSTeamPage) => setPSTeamPage(PSTeamPage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={psScoreTeamColumns}
                  dataSource={roundUpScore(psTeamTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={15} align="top" style={{ marginBottom: '20px' }}>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Project NPS Score
                  </Title>
                  <Button onClick={() => StatisticsNPSScore('Engagement')} loading={isLoadingDownloadEngNPS}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (NPSpage) => setNPSPage(NPSpage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={npsScoreColumns}
                  dataSource={roundUpScore(npsTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="statistic-container scroll-table">
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Team NPS Score
                  </Title>
                  <Button onClick={() => StatisticsNPSScore('Team')} loading={isLoadingDownloadTeamNPS}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Table
                  pagination={{
                    onChange: (NPSTeamPage) => setNPSTeamPage(NPSTeamPage),
                    defaultPageSize: 5,
                    simple: mobileSize ? true : false,
                    showSizeChanger: false,
                  }}
                  columns={npsScoreTeamColumns}
                  dataSource={roundUpScore(npsTeamTableData, 'teamId', 'teamName')}
                />
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};
