import { Line, LineConfig } from '@ant-design/charts';
import { isEmpty, maxBy, minBy, orderBy, uniq, uniqBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { COLOR_ABOVE_TARGET, DATE_ID_FORMAT } from '../common/constants';
import { Empty, Select } from 'antd';
import { targetAnnotation } from '../utils/chartUtils';
import { useFetch } from '../hooks/useFetch';
import { fetchKPI } from '../apis/customMetric';
import { ChartType } from '../types/note';
import TextArea from 'antd/lib/input/TextArea';
import { ChartNote } from '../charts/ChartNote';

interface IProps {
  type: string;
  chartType: ChartType;
  selectedDate: number;
}

export const ProjectMetricTrending = ({ type, chartType, selectedDate }: IProps) => {
  const { scores, chartNote, teamId, setChartNote, targetDates } = useContext(ProjectDataContext);
  const endDateId = moment().format(DATE_ID_FORMAT);
  const startDateId = moment().subtract(12, 'months').format(DATE_ID_FORMAT);
  const scoresWithin12Months = scores.filter((item) => +endDateId >= item.dateId && item.dateId >= +startDateId);
  const { data: kpiList } = useFetch(() => fetchKPI());
  const [selectedKPI, setSelectedKPI] = useState<any>();

  let preTargetDateId = targetDates[0];
  const data = orderBy(scoresWithin12Months, (item) => item.dateId).flatMap((item) => {
    const month = moment(item.dateId, DATE_ID_FORMAT).format('MMM YY');
    if (item.kpiData.length === 0) return [];
    else {
      var temp = [];
      kpiList?.forEach((kpi) => {
        let findData = item.kpiData?.find((x) => x.kpiId === kpi.id);
        if (findData) {
          if (type === 'Actual') {
            temp.push({
              date: month,
              dateId: item.dateId,
              kpi: 'Target',
              kpiId: kpi.id,
              value: findData.target
                ? Number(findData.target?.toFixed(2))
                : Number(
                    scoresWithin12Months
                      .find((x) => x.dateId === preTargetDateId)
                      ?.kpiData?.find((k) => k.kpiId === kpi.id)
                      ?.target?.toFixed(2)
                  ),
            });
            temp.push({
              date: month,
              dateId: item.dateId,
              kpi: kpi.name,
              kpiId: kpi.id,
              value: Number(findData.actual?.toFixed(2)),
            });
            if (targetDates.some((x) => x === item.dateId)) preTargetDateId = item.dateId;
          } else
            temp.push({
              date: month,
              dateId: item.dateId,
              kpi: kpi.name,
              kpiId: kpi.id,
              value: Number(findData.score?.toFixed(2)),
            });
        }
      });
      return temp;
    }
  });
  const options = uniqBy(
    [...data].filter((item) => item.kpi !== 'Target'),
    'kpiId'
  )?.map((item) => ({
    label: item.kpi,
    value: item.kpiId,
  }));

  useEffect(() => {
    setSelectedKPI(options[0]);
  }, [kpiList]);

  if (!data?.length && data) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const config: LineConfig = {
    data: data.filter((item) => item.kpiId === selectedKPI?.value),
    height: 300,
    yField: 'value',
    xField: 'date',
    seriesField: 'kpi',
    lineStyle: ({ kpi }) => {
      if (kpi === 'Target') {
        return {
          lineDash: [2, 2],
          lineWidth: 1,
        };
      } else
        return {
          lineWidth: 2,
        };
    },
  };

  return (
    <>
      <Select
        defaultValue={options[0]?.label}
        options={options}
        onChange={(lable, option) => setSelectedKPI(option)}
        style={{ width: '200px', paddingBottom: '1rem' }}
      />
      <p>
        Definition: <i>{kpiList?.find((item) => item.id === selectedKPI?.value)?.description}</i>
      </p>
      <Line
        {...config}
        point={{
          shape: 'diamond',
          size: 5,
        }}
        label={{}}
        annotations={type.toLocaleLowerCase() === 'score' && targetAnnotation(scores.length)}
        legend={{}}
        tooltip={{
          customContent: (title, data) => {
            let content = chartNote?.find(
              (x) => x.chartType === chartType && x.dateId === data[0]?.data.dateId && x.metricId === selectedKPI?.value
            )?.note;

            let items = ``;
            for (let i = 0; i < data.length; i++) {
              items += `
              <li class="g2-tooltip-list-item" data-index="">
                  <span class="g2-tooltip-marker" style="background: ${data[i].color};"></span>
                  <span class="g2-tooltip-name">${data[i].data.kpi}</span>:
                  <span class="g2-tooltip-value">${data[i].value}</span>
              </li>
                  `;
            }

            return `
            <div class="g2-tooltip-title">${title}</div>
            <ul class="g2-tooltip-list">
              ${items}
              <li class="g2-tooltip-list-item" data-index="">
                  <span class="g2-tooltip-name">Note</span>:
                  <span>${!isEmpty(content) ? content : '<i>No note</i>'}</span>
              </li>
          </ul>`;
          },
        }}
      />
      <ChartNote
        data={chartNote}
        dateId={selectedDate}
        teamId={+teamId}
        chartType={chartType}
        metricId={selectedKPI?.value}
        setChartNote={setChartNote}
      />
    </>
  );
};
