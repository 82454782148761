import { SearchOutlined } from '@ant-design/icons';
import { Button, Empty, Input, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TssDataContext } from '../../contexts/TssDataContext';
import IconExternalLink from '../../icons/IconExternalLink';
import { npsTeamMeaSure } from '../../types/nps';
import { tssTeamMeaSure } from '../../types/tss';
import { StyledLinkCell } from './../StyledLinkCell.styled';

type TeamType = 'Engagement' | 'Team';

interface IProps {
  type: TeamType;
}

const CustomCell = ({ value }: any) => {
  return (
    <div style={{ color: value < 0 ? 'red' : 'green' }}>
      {value == null ? '' : isNaN(value) ? '' : value < 0 ? `▼${value}%` : `▲${value}%`}
    </div>
  );
};

const MyCustomCell = (value: any) => <CustomCell value={value} />;

const NameCell = (text: string, record: tssTeamMeaSure, projectLink = 'project') => (
  <StyledLinkCell>
    <Link target="_blank" to={`/${projectLink}/${record.teamId}`}>
      {text}
      <IconExternalLink />
    </Link>
  </StyledLinkCell>
);

const functionFilter = (selectedKeys, setSelectedKeys, confirm) => {
  return (
    <>
      <Input
        placeholder="Type search name"
        value={selectedKeys[0]}
        onChange={(data) => {
          setSelectedKeys(data.target.value ? [data.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {
          confirm();
        }}
        onBlur={() => {
          confirm();
        }}
      />
    </>
  );
};

const buildColumns = (projectLink?: string): ColumnType<tssTeamMeaSure>[] => [
  {
    dataIndex: 'name',
    title: 'Name',
    ellipsis: true,
    width: 250,
    render: (text, record) => NameCell(text, record, projectLink),
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) =>
      functionFilter(selectedKeys, setSelectedKeys, confirm),
    // eslint-disable-next-line react/display-name
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) => {
      return record.name.toLowerCase().includes(value.toString().toLowerCase());
    },
  },
  {
    dataIndex: 'tss',
    align: 'center',
    title: 'Tss',
    width: 80,
    sorter: (a, b) => a.tss - b.tss,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    dataIndex: 'tssMoM',
    align: 'center',
    width: 100,
    title: 'Change',
    render: MyCustomCell,
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.tssMoM - b.tssMoM,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
];

const pageSize = 5;

export const TssTable = ({ type }: IProps) => {
  const { engagmentActuals, teamActuals } = useContext(TssDataContext);
  const [page, setPage] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const breakpoint = 415;
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  const actualsNotNull =
    type === 'Engagement'
      ? engagmentActuals?.filter((item) => item.tss != null)
      : teamActuals?.filter((item) => item.tss != null);

  if (!actualsNotNull?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const cols: ColumnType<tssTeamMeaSure>[] = [
    {
      title: '#',
      render: (_, __, index) => index + (page - 1) * pageSize + 1,
      width: 50,
      align: 'center',
    },
    ...buildColumns(type === 'Engagement' ? type : undefined),
  ];

  return (
    <Table
      className="scroll-table"
      columns={cols}
      bordered
      dataSource={actualsNotNull}
      pagination={{
        pageSize,
        simple: mobileSize ? true : false,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      }}
    />
  );
};
