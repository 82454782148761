import { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { IProjectData, MeasureQuery, MonthlyData } from '../types/metrics';
import { DATE_ID_FORMAT, MIN_DATE_ID } from '../common/constants';
import { Spin } from 'antd';
import { fetchMonthlyScore, fetchScores, getChartNote } from '../apis/scoreClient';
import { TeamContext } from './TeamContext';
import { uniq } from 'lodash';
import { fetchProjectMetricFact } from '../apis/targetClient';
import { ChartNoteResponse } from '../types/note';

export const ProjectDataContext = createContext<IProjectData>(null);

interface IProps {
  projectId: string | number;
  children: React.ReactNode;
  dateId: string;
}

export const ProjectDataContextProvider = ({ dateId, projectId, children }: IProps) => {
  const query: MeasureQuery = {
    startDateId: moment(MIN_DATE_ID).format(DATE_ID_FORMAT),
    teamId: projectId,
  };
  const { teams } = useContext(TeamContext);
  teams.filter((item) => `${item.parentId}` === projectId);
  const { data: scores, loading, error } = useFetch(() => fetchScores(query), [projectId]);
  const { data: monthlyScores } = useFetch(() => fetchMonthlyScore(query), [projectId]);
  const { data: chartNoteOriginal } = useFetch(() => getChartNote(+projectId));
  const [chartNote, setChartNote] = useState<ChartNoteResponse[]>(chartNoteOriginal);
  const dateKeys = scores ? uniq([...scores].map((item) => item.dateId)).sort() : [];

  useEffect(() => {
    setChartNote(chartNoteOriginal);
  }, [chartNoteOriginal]);

  let targetDate: number[] = [];

  let formatMonthlyData: MonthlyData[] = [];
  monthlyScores?.forEach((row) => {
    const elementData: MonthlyData = {
      type: row?.name,
      label: row?.name.concat(row?.categoryName),
      theme: row?.categoryName,
      color: row?.color,
    };

    dateKeys.forEach((date) => {
      let dataByDate = row.kpiData?.find((item) => item.dateId === date);
      if (dataByDate?.target != null) targetDate.push(date);
      elementData[date] = {
        score: dataByDate?.score,
        actual: dataByDate?.actual,
        target: dataByDate?.target,
      };
    });
    formatMonthlyData.push(elementData);
  });

  const sortmonthlyScore = formatMonthlyData.sort((a, b) => (a.theme.toLowerCase() > b.theme.toLowerCase() ? 1 : -1));
  const sortTargetDate = targetDate ? uniq([...targetDate].map((item) => item)).sort() : [];

  const currentMonth = scores?.find((item) => `${item.dateId}` === dateId);

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (loading) {
    return <Spin />;
  }

  return (
    <Spin spinning={loading}>
      <ProjectDataContext.Provider
        value={{
          scores,
          teamId: projectId,
          dates: dateKeys,
          currentMonth: currentMonth,
          monthlyData: sortmonthlyScore,
          targetDates: sortTargetDate,
          chartNote,
          setChartNote,
        }}
      >
        {children}
      </ProjectDataContext.Provider>
    </Spin>
  );
};
