import { TeamResponse, TeamTypeEnum } from '../types/team';
import { groupBy, keys, max } from 'lodash';
import { monthOverMonth, npsMonthOverMonth } from './programData';
import moment from 'moment';
import { DATE_ID_FORMAT } from '../common/constants';
import {
  ITssData,
  tssAggregate,
  tssAggregateMeasure,
  tssDataResponse,
  tssTeamMeaSure,
  tssTeamScore,
} from '../types/tss';
import { averageBy } from './mathUtil';

export const tssAggregateMeasureByDate = (data: tssDataResponse[], dateId?: string): tssAggregateMeasure[] => {
  const dateGroups = groupBy(data, (item) => item.score.dateId);
  const dateIds = keys(dateGroups);
  dateId = dateId || max(dateIds);
  const current = dateId ? dateGroups[dateId] : null;

  const previousDateId = moment(dateId, DATE_ID_FORMAT).subtract(1, 'month').format(DATE_ID_FORMAT);
  const previous = dateGroups[previousDateId];

  return current?.map((item) => {
    const teamPrev = previous?.find((p) => p.score.teamId === item.score.teamId);
    return {
      dateId: item.score.dateId,
      teamId: item.score.teamId,
      team: item.score.team,
      tss: item.tssActual,
      previousTSS: teamPrev?.tssActual,
      tssMoM: monthOverMonth(item.tssActual, teamPrev?.tssActual),
    };
  });
};

export const tssAvg = (data: tssDataResponse) => {
  console.log(data);
  return (
    (data?.score?.tssBuildingCount +
      data?.score?.tssGrowingCount +
      data?.score?.tssSolidCount +
      data?.score?.tssStrongCount +
      data?.score?.tssLeadingCount) /
    5
  );
};
export const tssAggregateByDate = (data: tssDataResponse[], dateId?: string): tssAggregate[] => {
  const dateGroups = groupBy(data, (item) => item.score.dateId);
  const dateIds = keys(dateGroups);
  dateId = dateId || max(dateIds);
  const current = dateId ? dateGroups[dateId] : null;
  const previousDateId = moment(dateId, DATE_ID_FORMAT).subtract(1, 'month').format(DATE_ID_FORMAT);
  const previous = dateGroups[previousDateId];

  return current?.map((item) => {
    const teamPrev = previous?.find((p) => p.score.teamId === item.score.teamId);
    return {
      previousTSS: teamPrev?.tssActual,
      dateId: item.score.dateId,
      teamId: item.score.teamId,
      team: item.score.team,
      tss: item.tssActual,
      tSSGrowingCount: item.score.tssGrowingCount,
      tSSBuildingCount: item.score.tssBuildingCount,
      tSSSolidCount: item.score.tssSolidCount,
      tSSStrongCount: item.score.tssStrongCount,
      tSSLeadingCount: item.score.tssLeadingCount,
      tssScore: item.tssScore,
      tssPerformance: item.score.tssPerformance,
      previousScore: teamPrev?.tssScore,
      scoreMoM: monthOverMonth(item.tssScore, teamPrev?.tssScore),
      tssMoM: npsMonthOverMonth(item.score.tssPerformance, teamPrev?.score.tssPerformance),
    };
  });
};

export const toTeamMeasure = (items: tssAggregateMeasure[], teams: TeamResponse[]): tssTeamMeaSure[] => {
  return items?.map((item) => {
    const team = teams.find((t) => t.id === item.teamId);
    return { ...item, name: team.name, type: team.type };
  });
};

export const toTeamScore = (items: tssAggregate[], teams: TeamResponse[]): tssTeamScore[] => {
  return items?.map((item) => {
    const team = teams.find((t) => t.id === item.teamId);
    return { ...item, name: team.name, type: team.type };
  });
};

export const tssTransformData = (data: tssDataResponse[], teams: TeamResponse[], dateId?: string): ITssData => {
  const aggregationScore = tssAggregateByDate(data, dateId);
  const aggregationMeasure = tssAggregateMeasureByDate(data, dateId);
  const dataGroups = groupBy(aggregationScore, (item) => teams.find((t) => t.id === item.teamId)?.type);
  const measureGroups = groupBy(aggregationMeasure, (item) => teams.find((t) => t.id === item.teamId)?.type);
  const engagementTss = toTeamMeasure(measureGroups[TeamTypeEnum.Engagement], teams)?.filter(
    (item) => item.tss != null
  );
  const teamTss = toTeamMeasure(measureGroups[TeamTypeEnum.Team], teams)?.filter((item) => item.tss != null);

  return {
    programScore: { ...dataGroups[TeamTypeEnum.Program]?.[0], name: 'Program', type: 'Program' },
    departmentScores: toTeamScore(dataGroups[TeamTypeEnum.Department], teams),
    engagmentActuals: engagementTss,
    teamActuals: teamTss,
  };
};
