import { DownloadOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tag,
  Timeline,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDateids, kpiSearchTeams } from '../../apis/customMetric';
import { ExportKpiSearch } from '../../apis/excelClient';
import { fetchMeasureNameList } from '../../apis/healthyClient';
import { KpiSearchChart } from '../../charts/KpiSearchChart';
import { DATE_ID_FORMAT } from '../../common/constants';
import { EditableColumn } from '../../components/EditableTable';
import { StyledTeamSelect } from '../../components/NoteDrawer.styled';
import { TeamSelect } from '../../components/TeamSelect';
import { TeamContext } from '../../contexts/TeamContext';
import { useFetch } from '../../hooks/useFetch';
import { KPISearchTeamsResponse } from '../../types/metrics';
import downloadFileFromBlob from '../../utils/downloadFileFromBlob';

export const KPISearchTab = () => {
  const [validYears, setValidYears] = useState<string[]>([]);
  const [filterDateId, setFilterDateId] = useState(null);
  const [filterValue, setFilterValue] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [filterDisabled, setFilterDisabled] = useState(true);
  const { data: dateIds } = useFetch(() => getDateids());
  const { teams } = useContext(TeamContext);
  const [validMonthsYears, setValidMonthsYears] = useState<number[]>([]);
  const [datafilter, setDataFilter] = useState<KPISearchTeamsResponse[]>();
  const [filterMeasureValue, setFilterMeasureValue] = useState<string[]>(['All']);
  const { data: filterFormMeasure } = useFetch(fetchMeasureNameList);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const [kpiTeam, setKpiTeam] = useState<KPISearchTeamsResponse[]>();
  const [isLoadingDownLoad, setIsLoadingDownLoad] = useState<boolean>(false);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  const { Panel } = Collapse;
  const breakpoint = 415;

  const handleMonthChanged = (dateId: string) => {
    let dateNumber = parseInt(dateId, 10);
    setFilterDateId(dateNumber);
  };
  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
    const TorF = filterValue === 2 ? true : false;
    TorF && setSelectedTeam(undefined);
    setFilterDisabled(TorF);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e);
  };

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const result = await kpiSearchTeams(filterDateId);
        setKpiTeam(result);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [filterDateId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  useEffect(() => {
    var arrMonthsList = [];
    dateIds?.map((item) => arrMonthsList.push(item));
    const validMonthsList = arrMonthsList.sort((a, b) => b - a);
    setValidMonthsYears(validMonthsList);
    const years = validMonthsList?.map((year) => moment(year, DATE_ID_FORMAT).format('YYYY'));
    setValidYears(Array.from(new Set(years)));
    setFilterDateId(validMonthsList[0]);
  }, [dateIds]);

  const functionFilter = (selectedKeys, setSelectedKeys, confirm) => {
    return (
      <>
        <Input
          placeholder="Type search name"
          value={selectedKeys[0]}
          onChange={(data) => {
            setSelectedKeys(data.target.value ? [data.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        />
      </>
    );
  };
  const columns: EditableColumn<KPISearchTeamsResponse>[] = [
    {
      title: '#',
      dataIndex: 'index',
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
      width: '4%',
      fixed: 'left',
      editable: false,
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      width: '15%',
      fixed: 'left',
      editable: false,
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) =>
        functionFilter(selectedKeys, setSelectedKeys, confirm),
      // eslint-disable-next-line react/display-name
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.categoryName.toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: 'KPI',
      dataIndex: 'kpiName',
      width: '20%',
      editable: false,
    },
    {
      title: 'Desciption',
      dataIndex: 'description',
      width: '30%',
      editable: false,
    },
    {
      title: 'Using by teams',
      dataIndex: 'teamIds',
      editable: true,
      inputType: 'comment',
      // eslint-disable-next-line react/display-name
      render: (kpiName) => (
        <>
          {kpiName?.map((item) => {
            var team = teams.find((t) => t.id === item);
            return (
              <Tag className="missing-actual-tag" key={team?.name}>
                <Link to={`/project/${team?.id}?dateId=${filterDateId}`}>{team?.name?.toUpperCase()}</Link>
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const handleFilter = (value) => {
    if (value.title === 'All') {
      setFilterMeasureValue(['All']);
    } else {
      let query = [];
      if (filterMeasureValue.find((item) => item === 'All')) {
        setFilterMeasureValue([value.title]);
      } else {
        if (filterMeasureValue.find((item) => item === value.title)) {
          query = filterMeasureValue.filter((item) => item !== value.title);
          setFilterMeasureValue(query);
        } else {
          query = filterMeasureValue.concat(value.title);
          setFilterMeasureValue(query);
        }
        if (query.length === 0) {
          setFilterMeasureValue(['All']);
        }
      }
    }
  };

  useEffect(() => {
    if (filterMeasureValue[0] === 'All') {
      setDataFilter(kpiTeam);
    } else {
      let checkData = kpiTeam?.filter((item) => filterMeasureValue.includes(item.kpiName));
      setDataFilter(checkData);
    }
  }, [filterMeasureValue, filterDateId, kpiTeam]);

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleMonthChangeds = (record) => {
    const months = validMonthsYears?.filter((element) => moment(element, DATE_ID_FORMAT).format('YYYY') === record);
    return months.map((month) => moment(month, DATE_ID_FORMAT).format('MMMM'));
  };

  const hexToRGB = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex !== null ? hex : '#ffffff');
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.4)`;
  };

  const downloadFile = async (dateId: number) => {
    try {
      setIsLoadingDownLoad(true);
      const result = await ExportKpiSearch(dateId);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDownLoad(false);
    }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <div className="guideline_section-title-container">
        <span className="guideline_section-title">KPI Search</span>
      </div>
      <Row className="row">
        {mobileSize === false ? (
          <Col xxl={4} xl={4}>
            <Card className="filter-actions-section" title="">
              <Space direction="vertical">
                <Title level={4}>Healthcheck</Title>
                {validYears?.map((element) => {
                  const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                  const uniqueMonths = handleMonthChangeds(year);
                  return (
                    <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                      <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                        <Timeline>
                          {uniqueMonths?.map((month) => {
                            const monthFormated = moment(month, 'MMMM').format('MM');
                            return (
                              <Timeline.Item key={year + monthFormated}>
                                <Button
                                  style={{ color: year + monthFormated == filterDateId ? 'white' : 'black' }}
                                  onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                  type={year + monthFormated == filterDateId ? 'primary' : 'text'}
                                >
                                  {month}
                                </Button>
                              </Timeline.Item>
                            );
                          })}
                        </Timeline>
                      </Panel>
                    </Collapse>
                  );
                })}
                <Title level={4}>Project</Title>
                <Radio.Group onChange={onFilterChange} value={filterValue}>
                  <Space direction="vertical">
                    <Radio value={1}>All</Radio>
                    <Radio value={2}>Select a destination</Radio>
                  </Space>
                </Radio.Group>
                <StyledTeamSelect className="filter-searchbox">
                  <TeamSelect
                    type={['Engagement', 'Team']}
                    allowClear
                    showSearch
                    onChange={handleTeamChange}
                    value={selectedTeam}
                    disabled={filterDisabled}
                    placeholder="Search for project team"
                  />
                </StyledTeamSelect>
              </Space>
            </Card>
          </Col>
        ) : (
          <Col className="chart-Program" xxl={8} xl={8}>
            <Collapse accordion>
              <Panel header="TimeLine" key="1">
                <Card className="filter-actions-section" title="">
                  <Space direction="vertical">
                    <Title level={4}>Healthcheck</Title>
                    {validYears?.map((element) => {
                      const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                      const uniqueMonths = handleMonthChangeds(year);
                      return (
                        <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                          <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                            <Timeline>
                              {uniqueMonths?.map((month) => {
                                const monthFormated = moment(month, 'MMMM').format('MM');
                                return (
                                  <Timeline.Item key={year + month}>
                                    <Button
                                      style={{ color: year + monthFormated === filterDateId ? 'white' : 'black' }}
                                      onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                      type={year + monthFormated === filterDateId ? 'primary' : 'text'}
                                    >
                                      {month}
                                    </Button>
                                  </Timeline.Item>
                                );
                              })}
                            </Timeline>
                          </Panel>
                        </Collapse>
                      );
                    })}
                    <Title level={4}>Engagement</Title>
                    <Radio.Group onChange={onFilterChange} value={filterValue}>
                      <Space direction="vertical">
                        <Radio value={1}>All</Radio>
                        <Radio value={2}>Select a destination</Radio>
                      </Space>
                    </Radio.Group>
                    <StyledTeamSelect className="filter-searchbox">
                      <TeamSelect
                        type={['Engagement', 'Team']}
                        allowClear
                        showSearch
                        onChange={handleTeamChange}
                        value={selectedTeam}
                        disabled={filterDisabled}
                        placeholder="Search for project team"
                      />
                    </StyledTeamSelect>
                  </Space>
                </Card>
              </Panel>
            </Collapse>
          </Col>
        )}
        <Col xxl={20} xl={20}>
          {mobileSize === false ? (
            <>
              <Row className="btn-healthCheck">
                {filterFormMeasure?.map((item, idx) => {
                  let btnOnCheck = filterMeasureValue.indexOf(item.title) > -1;
                  return (
                    <Button
                      className="btn-filter-measure"
                      style={
                        btnOnCheck
                          ? { backgroundColor: item.color, borderWidth: 2, borderColor: 'black' }
                          : { backgroundColor: hexToRGB(item.color) }
                      }
                      key={idx}
                      onClick={() => {
                        handleFilter(item);
                      }}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </Row>
              {loading && (
                <Spin
                  indicator={antIcon}
                  tip="loading..."
                  style={{ display: 'flex', justifyContent: 'center' }}
                  size="large"
                />
              )}
              {datafilter && <KpiSearchChart data={datafilter} measureData={filterFormMeasure} />}
            </>
          ) : (
            <Collapse accordion>
              <Panel header="Filter" key="1">
                <Row className="btn-healthCheck">
                  {filterFormMeasure?.map((item, idx) => {
                    let btnOnCheck = filterMeasureValue.indexOf(item.title) > -1;
                    return (
                      <Button
                        className="btn-filter-measure"
                        style={
                          btnOnCheck
                            ? { backgroundColor: item.color, borderWidth: 2, borderColor: 'black' }
                            : { backgroundColor: hexToRGB(item.color) }
                        }
                        key={idx}
                        onClick={() => {
                          handleFilter(item);
                        }}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
                </Row>
              </Panel>
            </Collapse>
          )}
          <Row className="btn-DownLoadKpiSearch">
            <Button
              size="large"
              onClick={() => downloadFile(filterDateId)}
              loading={isLoadingDownLoad}
              style={{ float: 'right' }}
            >
              <DownloadOutlined />
            </Button>
          </Row>
          <Row className="table-missing-measure">
            {datafilter && (
              <Table columns={columns} dataSource={datafilter} bordered onChange={onChange} scroll={{ x: '1000px' }} />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};
