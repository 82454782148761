import { Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';

import { ProgramScore } from '../charts/ProgramScore';
import { Card } from '../components/Card';
import { SolutionSetScores } from '../charts/SolutionSetScores';
import { MonthOverMonth } from '../charts/MonthOverMonth';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../common/constants';
import { ProgramDataContext, ProgramDataContextProvider } from '../contexts/ProgramDataContext';
import { useHistory, useLocation } from 'react-router';
import { ProgramPerformance } from '../charts/ProgramPerformance';
import { EngagementHeatmap } from '../charts/EngagementHeatmap';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { DownloadOutlined } from '@ant-design/icons';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { ExportScore } from '../apis/scoreClient';
import Title from 'antd/lib/typography/Title';

interface IProgramDatePickerProps {
  dateId?: string;
}

const ProgramDatePicker = ({ dateId }: IProgramDatePickerProps) => {
  const history = useHistory();
  const { programScore } = useContext(ProgramDataContext);
  const date = dateId || programScore?.dateId;
  const month = date ? moment(`${date}`, DATE_ID_FORMAT) : null;
  return (
    <DatePicker
      value={month}
      disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
      onChange={(e) => {
        e && history.push(`/dashboard?dateId=${e.format(DATE_ID_FORMAT)}`);
      }}
      format={MONTH_PICKER_FORMAT}
      picker="month"
      allowClear={false}
    />
  );
};

export const ProgramDashboard = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dateId = params.get('dateId');
  const month = dateId ? moment(dateId, DATE_ID_FORMAT) : null;
  const [isLoadingDownloadEngagement, setIsLoadingDownloadEngagement] = useState<boolean>(false);
  const [isLoadingDownloadTeam, setIsLoadingDownloadTeam] = useState<boolean>(false);

  const ExportData = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(true) : setIsLoadingDownloadTeam(true);
      const result = await ExportScore(Number(dateId), type);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(false) : setIsLoadingDownloadTeam(false);
    }
  };
  document.title = 'Vendor Score Card';
  return (
    <ProgramDataContextProvider month={month?.toDate()}>
      <Helmet>
        <title>Vendor Score Card</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container section-padding">
          <div className="section-title-container">
            <span className="section-title">Program Overview</span>
            <ProgramDatePicker dateId={dateId} />
          </div>
          <Row gutter={24}>
            <Col className="chart-Program" xs={24} sm={12} xxl={5}>
              <Card title="Program Score">
                <ProgramScore />
              </Card>
            </Col>
            <Col className="chart-Program" xs={24} sm={12} xxl={5}>
              <Card title="Project Performance">
                <ProgramPerformance type="engagement" />
              </Card>
            </Col>
            <Col className="chart-Program" xs={24} sm={12} xxl={5}>
              <Card title="Team Performance">
                <ProgramPerformance type="team" />
              </Card>
            </Col>
            <Col className="chart-Program" xs={24} sm={12} xxl={9}>
              <Card title="Scores by Department">
                <SolutionSetScores />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="chart-Program" xxl={12} xl={24}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Project Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Engagement')} loading={isLoadingDownloadEngagement}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Row>
                  <MonthOverMonth type="engagement" />
                </Row>
              </Card>
            </Col>
            <Col className="chart-Program" xxl={12} xl={24}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Team Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Team')} loading={isLoadingDownloadTeam}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <Row>
                  <MonthOverMonth type="team" />
                </Row>
              </Card>
            </Col>
          </Row>
        </section>
        <EngagementHeatmap month={month?.toDate()} />
      </div>
    </ProgramDataContextProvider>
  );
};
