import { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { ChartNoteRequest, ChartNoteResponse, ChartType } from '../types/note';
import { updateChartNote } from '../apis/scoreClient';
import moment from 'moment';
import { DATE_ID_FORMAT } from '../common/constants';
import { insertItem, updateItem } from '../utils/tableUtils';

interface IProps {
  data: ChartNoteResponse[];
  dateId: number;
  teamId: number;
  chartType: ChartType;
  metricId?: number;
  setChartNote?: (chartNote: ChartNoteResponse[]) => void;
}

export const ChartNote = ({ data, dateId, teamId, chartType, metricId, setChartNote }: IProps) => {
  const noteRef = useRef(null);
  const [content, setContent] = useState<ChartNoteResponse>();
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setContent(
      data?.find(
        (x) => x.chartType === chartType && x.dateId === dateId && x.metricId === metricId && x.teamId === teamId
      )
    );
  }, [data, dateId, teamId, chartType, metricId]);
  const onUpdate = async (value) => {
    try {
      let request: ChartNoteRequest = {
        id: content?.id,
        dateId: dateId,
        teamId: teamId,
        chartType: chartType,
        metricId: metricId,
        note: value,
      };
      const newData = await updateChartNote(request);
      if (content?.id) {
        setChartNote(updateItem(newData, data));
      } else {
        setChartNote(insertItem(newData, data));
      }
      setContent(newData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <span>
            <strong>Note - {moment(dateId, DATE_ID_FORMAT).format('MMM YYYY')}</strong>
          </span>

          {isEdit ? (
            <span data-html2canvas-ignore>
              <Button
                type="link"
                onClick={async () => {
                  await onUpdate(noteRef.current.resizableTextArea.textArea.value);
                  setIsEdit(false);
                }}
              >
                Save
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  // Revert back to old note

                  setIsEdit(false);
                }}
              >
                <Button type="link">Cancel</Button>
              </Popconfirm>
            </span>
          ) : (
            <Button
              data-html2canvas-ignore
              type="link"
              onClick={() => {
                // Set this for revert back when click 'Cancel'
                setIsEdit(true);
              }}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {isEdit ? (
            <TextArea
              defaultValue={content?.note}
              rows={4}
              showCount
              autoSize={{
                minRows: 4,
                maxRows: 10,
              }}
              ref={noteRef}
            />
          ) : (
            <pre
              className="notes"
              style={{
                maxHeight: '100px',
                overflowY: 'auto',
                overflowX: 'hidden',
                whiteSpace: 'pre-wrap',
                fontFamily: 'Segoe UI',
              }}
            >
              {!isEmpty(content?.note) ? content?.note : <i>No note</i>}
            </pre>
          )}
        </Col>
      </Row>
    </div>
  );
};
