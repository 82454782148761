import { orderBy, startCase } from 'lodash';
import { Col, Radio, Row, Table, Grid, Button } from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useFetch } from '../hooks/useFetch';

import { SelectOptions, TeamResponse, TeamType, TeamTypeEnum } from '../types/team';
import { measureOptions, MetricsType, SentimentFactResponse, TeamScore } from '../types/metrics';
import { ProgramDataContext } from '../contexts/ProgramDataContext';
import { ColumnType } from 'antd/lib/table';
import { CustomDrawer, CustomDrawer as NoteList } from '../components/NoteDrawer';

import './HeatMap.scss';
import { TeamSelect } from '../components/TeamSelect';
import { TeamContext } from '../contexts/TeamContext';
import {
  BAD_SENTIMENT,
  BELOW_TARGET,
  Category,
  DATE_ID_FORMAT,
  GOOD_SENTIMENT,
  Sentiment,
  SENTIMENT_NEG_CEll_COLOR,
  SENTIMENT_NEG_COLOR,
  SENTIMENT_NEUTRAL_CELL_COLOR,
  SENTIMENT_POS_CELL_COLOR,
  SENTIMENT_POS_COLOR,
} from '../common/constants';
import IconExternalLink from '../icons/IconExternalLink';
import { Link } from 'react-router-dom';
import { ColorScale, IColorScale } from '../components/ColorScale';
import moment from 'moment';
import { StyledLinkCell } from './StyledLinkCell.styled';

import { getNotiEngagement } from '../apis/teamClient';
import { fetchSentiment, fetchSentimentFact } from '../apis/fetchsentiment';
import { fetchCategory } from '../apis/fetchCategory';
import { fetchKPI } from '../apis/customMetric';
import { DownloadOutlined } from '@ant-design/icons';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { ExportHeatMapEngagement } from '../apis/excelClient';

const { useBreakpoint } = Grid;
const scales: IColorScale[] = [
  {
    color: 'rgb(216, 43, 34)',
    value: 0,
  },
  {
    color: 'rgb(216, 43, 34, 0.1)',
    value: 0.75,
  },
  {
    color: 'rgb(24, 144, 255, 0.15)',
    value: 0.75,
  },
  {
    color: 'rgb(24, 144, 255, 0.7)',
    value: 0.9,
  },
  {
    color: 'rgb(49, 126, 255, 0.7)',
    value: 0.9,
  },
  {
    color: 'rgb(49, 126, 255)',
    value: 1,
  },
];

const getColorScale = (value: number) => {
  if (value === null || isNaN(value)) {
    return '#cccc';
  }

  if (value < BELOW_TARGET) {
    // return `rgba(255, 38, 38, ${1 - value / 0.9})`;
    return `rgba(216, 43, 34, ${1 - value / 0.9})`;
  } else if (value >= 0.75 && value <= 0.9) {
    var result = 1 - (1 - value) / (value - 0.49);
    return `rgb(24, 144, 255, ${result < 0.1 ? 0.1 : result})`;
    // } else if (value > 0.75) {
  } else if (value > 0.9) {
    return `rgb(49, 126, 255, ${1 - (1 - value) / (value - 0.49)})`;
    // return `rgb(0, 138, 93, ${1 - (1 - value) / (value - 0.79)})`;
    // return `rgb(8, 94, 192, ${1 - (1 - value) / (value - 0.49)})`;
  }
};

const getColorSentimentScale = (value: number) => {
  if (value == null) {
    return '#fff';
  }
  switch (value) {
    case 1:
      return SENTIMENT_POS_CELL_COLOR;
    case -1:
      return SENTIMENT_NEG_CEll_COLOR;
    default:
      return SENTIMENT_NEUTRAL_CELL_COLOR;
  }
};

const teamOptions: SelectOptions<TeamType>[] = [
  {
    label: 'Department',
    value: 'Department',
  },
  {
    label: 'Project',
    value: 'Engagement',
  },
];

interface IColumn extends ColumnType<TeamScore> {
  dataIndex: keyof TeamScore;
  title?: string;
  className?: string;
}

const createHeatmapColumns = (colNames: IColumn[], width: string | number, type?: string): ColumnType<TeamScore>[] => {
  return colNames.map(
    (column) =>
      ({
        ...column,
        title: column.title || column.dataIndex,
        className: classnames('heatmap-column', column.className),
        sortDirections: ['ascend', 'descend', 'ascend'],
        width,
        sorter: (a: any, b: any) => {
          let a1 = a[column.dataIndex] === null || a[column.dataIndex] === undefined ? -999 : a[column.dataIndex];
          let b1 = b[column.dataIndex] === null || b[column.dataIndex] === undefined ? -999 : b[column.dataIndex];
          if (column.dataIndex !== 'score') {
            let aScore =
              type === 'kpi'
                ? a.kpiData?.find((item) => item.kpiName === column.dataIndex)?.score
                : a.categoryData?.find((item) => item.category.name === column.dataIndex)?.score;
            let bScore =
              type === 'kpi'
                ? b.kpiData?.find((item) => item.kpiName === column.dataIndex)?.score
                : b.categoryData?.find((item) => item.category.name === column.dataIndex)?.score;
            a1 = aScore === null || aScore === undefined ? -999 : aScore;
            b1 = bScore === null || bScore === undefined ? -999 : bScore;
          }
          return a1 - b1;
        },
        onCell: (record: TeamScore) =>
          ({
            dataIndex: column.dataIndex,
            record,
            min: 0.5,
            max: 1,
          } as any),
      } as ColumnType<TeamScore>)
  );
};

interface HeatMapCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string;
  record: TeamScore;
  min?: number;
  max?: number;
  children: React.ReactNode;
}

export const hasParentId = (id: number, parentId: number, teams: TeamResponse[]): boolean => {
  if (id === parentId) {
    return true;
  }

  const team = teams.find((item) => item.id === id);
  if (!team) {
    return false;
  }
  if (team.parentId === parentId) {
    return true;
  }

  return hasParentId(team.parentId, parentId, teams);
};

const HeatMapCategoryCell = ({ children, record, min, max, dataIndex, className, ...restProps }: HeatMapCellProps) => {
  const isHeatMapCell = className.indexOf('heatmap-column') >= 0;
  let color: string;
  let content: React.ReactNode = children;
  if (isHeatMapCell) {
    if (dataIndex === 'score') {
      color = getColorScale(Number(record[dataIndex]?.toFixed(2)));
      content = (record[dataIndex] as number)?.toFixed(2);
    } else {
      let cellscore = record.categoryData.find((item) => item.category.name === dataIndex)?.score;
      color = getColorScale(Number(cellscore?.toFixed(2)));
      content = (cellscore as number)?.toFixed(2);
    }
  }

  return (
    <td className={className} {...restProps} style={{ background: color }}>
      {content}
    </td>
  );
};

const HeatMapKPICell = ({ children, record, min, max, dataIndex, className, ...restProps }: HeatMapCellProps) => {
  const isHeatMapCell = className.indexOf('heatmap-column') >= 0;
  let color: string;
  let content: React.ReactNode = children;
  if (isHeatMapCell) {
    if (dataIndex === 'score') {
      color = getColorScale(Number(record[dataIndex]?.toFixed(2)));
      content = (record[dataIndex] as number)?.toFixed(2);
    } else {
      let cellscore = record.kpiData.find((item) => item.kpiName === dataIndex)?.score;
      color = getColorScale(Number(cellscore?.toFixed(2)));
      content = (cellscore as number)?.toFixed(2);
    }
  }

  return (
    <td className={className} {...restProps} style={{ background: color }}>
      {content}
    </td>
  );
};
const HeatMapSentimentCell = ({ children, record, min, max, dataIndex, className, ...restProps }: HeatMapCellProps) => {
  const isHeatMapCell = className.indexOf('heatmap-column') >= 0;
  let backgroundcolor: string;
  let textcolor: string;
  let fontweight: any;
  let content: React.ReactNode = children;
  if (isHeatMapCell) {
    if (dataIndex !== GOOD_SENTIMENT && dataIndex !== BAD_SENTIMENT) {
      backgroundcolor = getColorSentimentScale(record[dataIndex]);
      content = null;
      fontweight = 'lighter';
    } else {
      if (dataIndex === GOOD_SENTIMENT) textcolor = SENTIMENT_POS_COLOR;
      if (dataIndex === BAD_SENTIMENT) textcolor = SENTIMENT_NEG_COLOR;
      content = (record[dataIndex] as number)?.toFixed(0);
      fontweight = 'bolder';
    }
  }

  return (
    <td
      className={className}
      {...restProps}
      style={{ background: backgroundcolor, color: textcolor, fontWeight: fontweight }}
    >
      {content}
    </td>
  );
};
interface IProps {
  month: Date;
}

export const EngagementHeatmap = ({ month }: IProps) => {
  const [activeType, setActiveType] = useState<TeamType>('Engagement');
  const [activeMetrics, setActiveMetrics] = useState<MetricsType>('Category');
  const [activeDepartment, setDepartment] = useState<number>();
  const [activeEngagement, setEngagement] = useState<number>();
  const { departmentScores, engagementScores } = useContext(ProgramDataContext);
  const { teams } = useContext(TeamContext);
  const { programScore } = useContext(ProgramDataContext);
  const [widthMobileSize, setWidthMobileSize] = useState<string>('10%');
  const { data: sentimentMetricData } = useFetch(() => fetchSentiment());
  const { data: CategoryList } = useFetch(() => fetchCategory());
  const { data: KpiList } = useFetch(() => fetchKPI());
  const [sentimentFact, setSentimentFact] = useState<SentimentFactResponse[]>();
  const [isLoadingDownloadEngagement, setIsLoadingDownloadEngagement] = useState<boolean>(false);

  const date = month ? month : programScore?.dateId;

  const dateId = date ? moment(date, DATE_ID_FORMAT).toDate() : null;

  const { data: notiEngagement } = useFetch(() => {
    let query = month ? parseInt(moment(month).format('YYYYMM')) : 0;
    return getNotiEngagement(query);
  }, [month]);

  let data: TeamScore[];
  switch (activeType) {
    case 'Department':
      data = departmentScores;
      break;
    default:
      data = engagementScores;
      break;
  }
  useEffect(() => {
    getSentimentFact(parseInt(moment(dateId).format('YYYYMM')));
  }, [month, date]);

  const getSentimentFact = async (dateId: number) => {
    try {
      const sentimentFact = await fetchSentimentFact(dateId);
      setSentimentFact(sentimentFact ? sentimentFact : []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (name: string, type: string, teamId: number) => {
    const result = teams.find((item) => item.name === name).id;
    if (type === 'Department') {
      setActiveType('Engagement');
      setDepartment(result);
    }
  };
  const NameCell = (text, record: TeamScore) => {
    const navigateTo =
      activeMetrics === 'Sentiment'
        ? `/project/${record.teamId}?dateId=${Number(moment(dateId).format(DATE_ID_FORMAT))}`
        : record.type === 'Engagement'
        ? `/engagement/${record.teamId}?dateId=${record.dateId}`
        : '';
    const content = (
      <span
        onClick={() => {
          handleFilter(record.name, record.type, record.teamId);
        }}
        style={{ cursor: 'pointer' }}
      >
        {record.name}
      </span>
    );
    return (
      <div className="ant-table-cell-ellipsis">
        {navigateTo ? (
          <StyledLinkCell>
            <Link to={navigateTo}>
              {content}
              <IconExternalLink />
            </Link>
          </StyledLinkCell>
        ) : (
          content
        )}
        {activeType === 'Department' ? (
          <CustomDrawer recordName={record.name} teamId={record.teamId} month={dateId} />
        ) : (
          <CustomDrawer
            recordName={record.name}
            teamId={record.teamId}
            month={dateId}
            noti={notiEngagement?.find((x) => x.teamId === record.teamId)?.notified}
          />
        )}
      </div>
    );
  };
  const nameColumn: ColumnType<TeamScore> = {
    dataIndex: 'name',
    title:
      activeMetrics === 'Sentiment'
        ? `Team Name`
        : activeType === TeamTypeEnum.Engagement
        ? 'Project Name'
        : `${activeType} Name`,
    ellipsis: true,
    fixed: 'left',
    render: NameCell,
    onCell: (data) => ({
      title: data.name,
    }),
    width: widthMobileSize,
  };

  const metricsColumns = (month: Date) => {
    const listColumnKPI = [];
    listColumnKPI.push({ dataIndex: 'score', className: 'heatmap-column-score' });
    KpiList?.filter((x) => x.isPublic === 1)
      ?.sort((a, b) => (a.categoryId > b.categoryId ? 1 : -1))
      ?.forEach((record) => {
        var KpiName = record.name;
        var color = CategoryList?.find((item) => item.id === record.categoryId)?.themeColor;
        listColumnKPI.push({ dataIndex: KpiName, className: `heatmap-column-${color?.replace('#', '')}` });
      });
    const collumns: ColumnType<TeamScore>[] = [nameColumn, ...createHeatmapColumns(listColumnKPI, '3%', 'kpi')];
    return collumns;
  };
  const themeColumns = (month: Date) => {
    const listColumnCategory = [];
    listColumnCategory.push({ dataIndex: 'score', className: 'heatmap-column-score' });
    CategoryList?.forEach((record) => {
      var CategoryName = record.name;
      listColumnCategory.push({
        dataIndex: CategoryName,
        className: `heatmap-column-${record?.themeColor?.replace('#', '')}`,
      });
    });
    const collumns: ColumnType<TeamScore>[] = [
      nameColumn,
      ...createHeatmapColumns(listColumnCategory, '10%', 'category'),
    ];
    return collumns;
  };

  const SentimentColumn = (month: Date) => {
    const listColumnSentiment = [];
    listColumnSentiment.push({ dataIndex: BAD_SENTIMENT, className: 'heatmap-column-notgood' });
    listColumnSentiment.push({ dataIndex: GOOD_SENTIMENT, className: 'heatmap-column-good' });
    sentimentMetricData.forEach((record) => {
      var sentimentName = record.name.replace(/\s/g, '');
      switch (record.posNegAttributeValue) {
        case -1:
          listColumnSentiment.push({ dataIndex: sentimentName, className: 'heatmap-column-nes' });
          break;
        case 1:
          listColumnSentiment.push({ dataIndex: sentimentName, className: 'heatmap-column-pos' });
          break;
        default:
          listColumnSentiment.push({ dataIndex: sentimentName, className: 'heatmap-column-zero' });
          break;
      }
    });
    const columns: ColumnType<TeamScore>[] = [nameColumn, ...createHeatmapColumns(listColumnSentiment, '200px')];
    return columns;
  };
  const handleActiveType = (value) => {
    setActiveType(value);
    if (activeMetrics === Sentiment) {
      setActiveMetrics(Category);
      setWidthMobileSize('40vw');
    }
  };

  const handleActiveMetrics = (value) => {
    setActiveMetrics(value);
    setWidthMobileSize('10%');
    if (value === 'KPI') {
      setActiveType('Department');
    } else if (value === Sentiment) {
      setActiveType('Team');
    } else {
      setActiveType('Department');
    }
  };
  let columns: ColumnType<TeamScore>[];
  switch (activeMetrics) {
    case 'Category':
      columns = themeColumns(dateId);
      break;
    case 'Sentiment':
      columns = SentimentColumn(dateId);
      break;
    default:
      columns = metricsColumns(dateId);
      break;
  }
  const handleDataSentimentColumn = (record: SentimentFactResponse) => {
    let list = {};
    let goodPoint = 0;
    let badPoint = 0;
    list['teamId'] = record.teamId;
    list['name'] = record.teamName;
    record.sentimentId?.forEach((item) => {
      let sentiment = sentimentMetricData.find((t) => t.id === item);
      list[sentiment.name.replace(/\s/g, '')] = sentiment.posNegAttributeValue;
      switch (sentiment.posNegAttributeValue) {
        case -1:
          badPoint++;
          break;
        case 1:
          goodPoint++;
          break;
        default:
          break;
      }
    });
    list[BAD_SENTIMENT] = -badPoint;
    list[GOOD_SENTIMENT] = goodPoint;
    return list;
  };
  const dataTableSentiment = () => {
    let listSentimentName = [];
    sentimentFact?.forEach((item) => {
      let customSentimentColumn = handleDataSentimentColumn(item);
      listSentimentName.push(customSentimentColumn);
    });
    return listSentimentName;
  };

  let tableDataSentiment = dataTableSentiment();
  let tableData = orderBy(
    data?.filter((item) => item.score != null),
    (item) => item.score,
    'asc'
  );
  if (activeDepartment) {
    tableData = tableData.filter((item) => hasParentId(item.teamId, activeDepartment, teams));
    tableDataSentiment = tableDataSentiment.filter((item) => hasParentId(item.teamId, activeDepartment, teams));
  }

  if (activeEngagement) {
    tableData = tableData.filter((item) => hasParentId(item.teamId, activeEngagement, teams));
    tableDataSentiment = tableDataSentiment.filter((item) => hasParentId(item.teamId, activeEngagement, teams));
  }

  const ExportDataHeatMap = async (type: string) => {
    try {
      setIsLoadingDownloadEngagement(true);
      const result = await ExportHeatMapEngagement(Number(moment(dateId).format(DATE_ID_FORMAT)), type);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      setIsLoadingDownloadEngagement(false);
    }
  };

  const { xl, lg, md } = useBreakpoint();

  return (
    <section className="section-container">
      <div className="section-title-container">
        <Row>
          <Col span={9}>
            <div className="section-title">Program Heatmap</div>
          </Col>
          <Col span={15}>
            <Button
              size="large"
              onClick={() => ExportDataHeatMap('Engagement')}
              loading={isLoadingDownloadEngagement}
              style={{ float: 'right' }}
            >
              <DownloadOutlined />
            </Button>
          </Col>
        </Row>
      </div>
      <div className="heatmap-container">
        {md ? (
          <Row className="filter-container">
            <Col lg={12}>
              <label className="heatmap-filter-label">View Hierarchy</label>
              <Radio.Group
                options={teamOptions}
                onChange={({ target: { value } }) => {
                  handleActiveType(value);
                }}
                value={activeType}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
            <Col flex="auto" style={{ textAlign: 'right' }}>
              <label className="heatmap-filter-label">Metrics Type</label>
              <Radio.Group
                options={measureOptions}
                onChange={({ target: { value } }) => {
                  handleActiveMetrics(value);
                }}
                value={activeMetrics}
                optionType="button"
                buttonStyle="solid"
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="filter-container">
              <label className="heatmap-filter-label">View Hierarchy</label>
              <Radio.Group
                options={teamOptions}
                onChange={({ target: { value } }) => {
                  handleActiveType(value);
                }}
                value={activeType}
                optionType="button"
                buttonStyle="solid"
              />
            </Row>

            <Row className="filter-container">
              <label className="heatmap-filter-label">Metrics Type</label>
              <Radio.Group
                options={measureOptions}
                onChange={({ target: { value } }) => {
                  handleActiveMetrics(value);
                }}
                value={activeMetrics}
                optionType="button"
                buttonStyle="solid"
              />
            </Row>
          </>
        )}
        {xl ? (
          <Row className="filter-container">
            <Col span={16}>
              <div className="heatmap-filter">
                <>
                  <label className="heatmap-filter-label">Department</label>
                  <TeamSelect
                    allowClear
                    showSearch
                    value={activeDepartment}
                    onChange={(value) => setDepartment(value)}
                    type="Department"
                    placeholder="Filter by Department"
                  />

                  <label className="heatmap-filter-label">Project</label>
                  <TeamSelect
                    value={activeEngagement}
                    showSearch
                    allowClear
                    onChange={(value) => setEngagement(value)}
                    type="Engagement"
                    placeholder="Filter by Project"
                  />
                </>
              </div>
            </Col>
            {activeMetrics !== 'Sentiment' && (
              <Col span={8}>
                <ColorScale scales={scales} />
              </Col>
            )}
          </Row>
        ) : (
          <>
            {lg ? (
              <>
                <Row className="filter-container">
                  <Col span={24}>
                    <div className="heatmap-filter">
                      <>
                        <label className="heatmap-filter-label">Department </label>
                        <TeamSelect
                          allowClear
                          showSearch
                          value={activeDepartment}
                          onChange={(value) => setDepartment(value)}
                          type="Department"
                          placeholder="Filter by Department"
                        />

                        <label className="heatmap-filter-label">Project</label>
                        <TeamSelect
                          value={activeEngagement}
                          showSearch
                          allowClear
                          onChange={(value) => setEngagement(value)}
                          type="Engagement"
                          placeholder="Filter by Engagement"
                        />
                      </>
                    </div>
                  </Col>
                </Row>
                <Row className="filter-container">
                  <Col span={24}>
                    <ColorScale scales={scales} />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="filter-container">
                  <Col span={24}>
                    <label className="heatmap-filter-label">Department</label>
                    <TeamSelect
                      allowClear
                      showSearch
                      value={activeDepartment}
                      onChange={(value) => setDepartment(value)}
                      type="Department"
                      placeholder="Filter by Department"
                    />
                  </Col>
                </Row>
                <Row className="filter-container">
                  <Col span={24}>
                    <label className="heatmap-filter-label">Project</label>
                    <TeamSelect
                      value={activeEngagement}
                      showSearch
                      allowClear
                      onChange={(value) => setEngagement(value)}
                      type="Engagement"
                      placeholder="Filter by Engagement"
                    />
                  </Col>
                </Row>
                <Row className="filter-container">
                  <Col span={24}>
                    <ColorScale scales={scales} />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        <Table
          rowKey="name"
          className={classnames('heatmap', { 'heatmap-text-small': activeMetrics === 'KPI' })}
          components={{
            body: {
              cell:
                activeMetrics === 'Sentiment'
                  ? HeatMapSentimentCell
                  : activeMetrics === 'KPI'
                  ? HeatMapKPICell
                  : HeatMapCategoryCell,
            },
          }}
          bordered
          dataSource={activeMetrics === 'Sentiment' ? tableDataSentiment : tableData}
          columns={columns}
          scroll={{ x: 'max-content' }}
          rowClassName="editable-row"
          pagination={{
            pageSize: 15,
          }}
        />
      </div>
    </section>
  );
};
