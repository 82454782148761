import { Bar } from '@ant-design/charts';
import { Empty } from 'antd';
import { startCase } from 'lodash';
import { useContext } from 'react';
import { BELOW_TARGET, COLOR_ABOVE_TARGET, COLOR_BELOW_TARGET, COLOR_ON_TARGET } from '../common/constants';
import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { AggregatedScore } from '../types/metrics';

const createDataGroups = (metrics: string[], data: AggregatedScore) => {
  return data.categoryData?.map((item) => ({
    label: item.category.name,
    value: Number(item.score?.toFixed(2)),
  }));
};

export const ThemeScore = () => {
  const { currentMonth } = useContext(ProjectDataContext);
  if (!currentMonth) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  const data = createDataGroups(['score'], currentMonth);

  return (
    <div className="theme-metrics-container">
      <Bar
        height={300}
        maxBarWidth={50}
        data={data}
        xField="value"
        yField="label"
        label={{
          position: 'middle',
        }}
        color={({ label }) => {
          var val = Number(data.find((item) => item.label === label)?.value.toFixed(2));
          if (val < BELOW_TARGET) {
            return COLOR_BELOW_TARGET;
          } else if (val >= 0.75 && val <= 0.9) {
            return COLOR_ON_TARGET;
          } else if (val > 0.9) {
            return COLOR_ABOVE_TARGET;
          }
        }}
      />
    </div>
  );
};
