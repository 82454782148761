import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router';
import { Breadcrumb, Button, Select } from 'antd';

import { ProjectScore } from '../charts/ProjectScore';
import { ThemeScore } from '../charts/ThemeMetrics';
import { MonthlyTable } from '../charts/MonthlyTable';
import { Card } from '../components/Card';
import { useContext, useState, useEffect } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import { ProjectDataContextProvider } from '../contexts/ProjectDataContext';
import { ProjectTrending } from '../charts/ProjectTrending';
import { Col, DatePicker, Row } from 'antd';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../common/constants';
import fetchTeamData from '../apis/fetchTeamData';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { Helmet } from 'react-helmet';
import { CustomDrawer } from '../components/NoteDrawer';
import { TeamScoreTrending } from '../charts/TeamScoreTrending';
import { Link } from 'react-router-dom';
import { ProjectPSFlowTrending } from '../charts/ProjectPSFlowTrending';
import { ProjectMetricTrending } from './ProjectMetricTrending';
import { ChartType } from '../types/note';

interface RouteParams {
  projectId: string;
}
const lastMonth = moment().subtract(1, 'month');

export const ProjectDashboard = () => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const { projectId } = useParams<RouteParams>();
  const { teams } = useContext(TeamContext);
  const history = useHistory();
  const team = teams.find((t) => `${t.id}` === projectId);
  const month = moment(params.get('dateId') || lastMonth, DATE_ID_FORMAT).toDate();
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

  const downloadFile = async () => {
    try {
      setIsLoadingDownload(true);
      const result = await fetchTeamData(team.id);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const getParent = (teamId: number) => {
    return teams?.find((item) => item.id === teamId);
  };
  return (
    <ProjectDataContextProvider dateId={moment(month).format(DATE_ID_FORMAT)} projectId={projectId}>
      <div className="page-home page-container">
        <div className="breadCumb_TeamDetail">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span style={{ color: '#1890ff' }}>{getParent(getParent(team.parentId)?.parentId)?.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/engagement/${getParent(team.parentId)?.id}`} style={{ color: '#1890ff' }}>
                {getParent(team.parentId)?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span style={{ color: '#00000073' }}>{team.name} Overview</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className="section-container section-padding">
          <div className="section-title-container">
            <span className="section-title">{team.name} Overview</span>
            <DatePicker
              value={moment(month)}
              disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
              onChange={(e) => {
                e && history.push(`${pathname}?dateId=${e.format(DATE_ID_FORMAT)}`);
              }}
              format={MONTH_PICKER_FORMAT}
              picker="month"
              allowClear={false}
            />
            <Button
              size="large"
              type="primary"
              className="download-button"
              onClick={downloadFile}
              loading={isLoadingDownload}
            >
              Download Report
            </Button>
            <CustomDrawer teamId={team.id} month={month} recordName={team.name} hasButtonText />
          </div>

          <Row gutter={24}>
            <Col className="chart-Program" lg={12} md={24}>
              <Card title="Project score">
                <ProjectScore />
              </Card>
            </Col>
            <Col className="chart-Program" lg={12} md={24}>
              <Card title="Score by Category">
                <ThemeScore />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="chart-Program" xl={12} lg={24}>
              <Card title="Score Trends">
                <TeamScoreTrending />
              </Card>
            </Col>
            <Col className="chart-Program" xl={12} lg={24}>
              <Card title="Team Score Trends">
                <ProjectTrending />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="chart-Program" xl={12} lg={24}>
              <Card title="Metric Score Flow Trends">
                <ProjectMetricTrending
                  type={'Score'}
                  chartType={ChartType.ProjectMetricScoreFlowTrends}
                  selectedDate={Number(moment(month).format(DATE_ID_FORMAT))}
                />
              </Card>
            </Col>
            <Col className="chart-Program" xl={12} lg={24}>
              <Card title="Metric Actual Flow Trends">
                <ProjectMetricTrending
                  type={'Actual'}
                  chartType={ChartType.ProjectMetricActualFlowTrends}
                  selectedDate={Number(moment(month).format(DATE_ID_FORMAT))}
                />
              </Card>
            </Col>
          </Row>
        </section>

        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Monthly Data</span>
          </div>
          <MonthlyTable dateId={Number(moment(month).format(DATE_ID_FORMAT))} projectId={Number(projectId)} />
        </section>
      </div>
    </ProjectDataContextProvider>
  );
};
