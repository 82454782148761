import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import { Card } from '../components/Card';
import { Helmet } from 'react-helmet';

import { ProgramTrendingDataContextProvider } from '../contexts/ProgramTrendingDataContext';
import { DepartmentTreding } from '../charts/trending/DepartmentTrending';
import { ProgramTreding } from '../charts/trending/ProgramTrending';
import { EngagementTrending } from '../charts/trending/EngagementTrending';
import { PSFlowTrending } from '../charts/trending/PSFlowTrending';
import { DepartmentNPSTreding } from '../charts/trending/DepartmentNPSTrending';
import { ProgramNPSTrending } from '../charts/trending/ProgramNPSTrending';
import { useFetch } from '../hooks/useFetch';
import { fetchCategory } from '../apis/fetchCategory';
import { CategoryResponse } from '../types/metrics';
import { OptionType } from 'antd/lib/select';

type Option = {
  label: string;
  value: number;
};
export const ProgramTrendingDashBoard = () => {
  const { data: categoryList } = useFetch(() => fetchCategory());
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [options, setOptions] = useState<Option[]>();

  const optionCategory = (categoryList: CategoryResponse[]) => {
    let options = categoryList?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
    return options;
  };
  useEffect(() => {
    if (categoryList) {
      let options = optionCategory(categoryList);
      setOptions(options);
      setSelectedCategory(options[0].value);
    }
  }, [categoryList]);
  return (
    <ProgramTrendingDataContextProvider>
      <Helmet>
        <title>Program Trends</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Program Trends</span>
          </div>
          <Row gutter={24} className="row fullwitdh-chart" justify="center" wrap={true}>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Program Score Trends">
                <ProgramTreding />
              </Card>
            </Col>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Department Score Trends">
                <DepartmentTreding />
              </Card>
            </Col>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Project Performance Trends">
                <EngagementTrending type="Engagement" />
              </Card>
            </Col>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Team Performance Trends">
                <EngagementTrending type="Team" />
              </Card>
            </Col>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Program NPS Trends">
                <ProgramNPSTrending />
              </Card>
            </Col>
            <Col xs={24} xxl={8} xl={12}>
              <Card title="Department NPS Trends">
                <DepartmentNPSTreding />
              </Card>
            </Col>
            <Col xs={24} xxl={12} xl={12}>
              <Card title="Coding Performance Score Flow Trends">
                {categoryList && (
                  <>
                    <Select
                      value={selectedCategory}
                      options={options}
                      onChange={(value: number) => setSelectedCategory(value)}
                    />
                    <PSFlowTrending teamtype={'Program'} type={'Score'} categoryId={selectedCategory} />
                  </>
                )}
              </Card>
            </Col>
            {/* <Col xs={24} xxl={12} xl={12}>
              <Card title="Coding Performance Actual Flow Trends">
                <PSFlowTrending teamtype={'Program'} type={'Actual'} />
              </Card>
            </Col> */}
          </Row>
        </section>
      </div>
    </ProgramTrendingDataContextProvider>
  );
};
