import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useContext } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './common/authConfig';

import { TeamContextProvider } from './contexts/TeamContext';
import { Header } from './components/PageHeader';
import { ImportVendorScoreCard } from './pages/ImportActual';
import { Login } from './pages/Login';
import { ManageUsers } from './pages/ManageUsers';
import { ManageTeams } from './pages/ManageTeams';
import { ProgramDashboard } from './pages/ProgramDashboard';
import { ProjectDashboard } from './pages/ProjectDashboard';
import { EngagementDashboard } from './pages/EngagementDashboard';
import { AuthContext, AuthContextProvider } from './contexts/AuthContext';
import { ImportTarget } from './pages/ImportTarget';
import { TargetConfiguration } from './pages/TargetConfiguration';
import { ImportTeams } from './pages/ImportTeams';
import { UserContextProvider } from './contexts/UserContext';
import { ProgramTrendingDashBoard } from './pages/ProgramTrendingDashBoard';
import { NpsDashboard } from './pages/NpsDashboard';
import { Profile } from './pages/Profile';
import { LoginRedirect } from './pages/LoginRedirect';
import { ScoreStatistics } from './pages/Statistics/ScoreStatistics';
import { GuidelineView } from './pages/Guideline/GuidelineView';
import { GuidelineContextProvider } from './contexts/GuidelineContext';
import { FeedbackDashboard } from './pages/FeedbackDashboard/FeedbackDashboard';
import { HealthCheckDashboard } from './pages/HealthCheck/HealthCheck';
import { ImportSentiment } from './pages/ImportSentiment';
import { KpiDefinition } from './pages/KpiDefinition/KpiDefinition';
import { TeamWeight } from './pages/ImportTeamWeight/ImportTeamWeight';
import { KpiManagement } from './pages/KpiManagement/KpiManagement';
import { ModifyTeamWeight } from './pages/ModifyTeamWeight/ModifyTeamWeight';
import { TssDashboard } from './pages/Tss/TssDashboard';
const msalInstance = new PublicClientApplication(msalConfig);

const AdminRoute = ({ children, ...props }: RouteProps) => {
  const {
    user: { isAdmin },
  } = useContext(AuthContext);

  return <Route {...props}>{isAdmin ? children : 'You are not allowed to view this page'}</Route>;
};

export const App = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Switch>
          <Route path="/signin_redirect">
            <LoginRedirect />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route>
            <AuthContextProvider>
              <TeamContextProvider>
                <UserContextProvider>
                  <GuidelineContextProvider>
                    <Header />
                    <div className="app-container">
                      <Switch>
                        <Route path="/" exact>
                          <Redirect to="/dashboard" />
                        </Route>
                        <Route path="/profile">
                          <Profile />
                        </Route>
                        <Route path="/dashboard">
                          <ProgramDashboard />
                        </Route>
                        <Route path="/relationship/npsdashboard">
                          <NpsDashboard />
                        </Route>
                        <Route path="/relationship/tssdashboard">
                          <TssDashboard />
                        </Route>
                        <Route path="/feedback">
                          <FeedbackDashboard />
                        </Route>
                        <Route path="/healthcheck">
                          <HealthCheckDashboard />
                        </Route>
                        <Route path="/statistic">
                          <ScoreStatistics />
                        </Route>
                        <Route path="/trending">
                          <ProgramTrendingDashBoard />
                        </Route>
                        <Route path="/project/:projectId">
                          <ProjectDashboard />
                        </Route>
                        <Route path="/engagement/:engagementId">
                          <EngagementDashboard />
                        </Route>
                        <Route path="/guideline">
                          <GuidelineView />
                        </Route>
                        <AdminRoute path="/admin/teams">
                          <ManageTeams />
                        </AdminRoute>
                        <AdminRoute path="/admin/users">
                          <ManageUsers />
                        </AdminRoute>
                        <AdminRoute path="/admin/importtarget">
                          <ImportTarget />
                        </AdminRoute>
                        <AdminRoute path="/admin/importactual">
                          <ImportVendorScoreCard />
                        </AdminRoute>
                        <AdminRoute path="/targets">
                          <TargetConfiguration />
                        </AdminRoute>
                        <AdminRoute path="/admin/importteams">
                          <ImportTeams />
                        </AdminRoute>
                        <AdminRoute path="/admin/import-sentiment">
                          <ImportSentiment />
                        </AdminRoute>
                        <AdminRoute path="/admin/kpi-management">
                          <KpiManagement />
                        </AdminRoute>
                        <AdminRoute path="/admin/kpi-definition">
                          <KpiDefinition />
                        </AdminRoute>
                        <AdminRoute path="/admin/import-teamweight">
                          <TeamWeight />
                        </AdminRoute>
                        <AdminRoute path="/admin/Modify-teamweight/:projectId">
                          <ModifyTeamWeight />
                        </AdminRoute>
                      </Switch>
                    </div>
                    <div className="page-footer">
                      Copyright © 2021 <a href="https://www.fpt-software.com/">FPT</a>. All rights reserved
                    </div>
                  </GuidelineContextProvider>
                </UserContextProvider>
              </TeamContextProvider>
            </AuthContextProvider>
          </Route>
        </Switch>
      </BrowserRouter>
    </MsalProvider>
  );
};
