import { Gauge } from '@ant-design/charts';
import { useContext } from 'react';
import { Empty } from 'antd';
import { GaugeConfig } from '@ant-design/charts/es';
import { round } from 'lodash';
import { TssDataContext } from '../../contexts/TssDataContext';
import {
  BUILDING_COLOR,
  GAUGE_BUILDING,
  GAUGE_GROWING,
  GAUGE_LEADING,
  GAUGE_SOLID,
  GAUGE_STRONG,
  GROWING_COLOR,
  LEADING_COLOR,
  SOLID_COLOR,
  STRONG_COLOR,
} from '../../common/constants';

export const TssStatus = () => {
  const {
    programScore: { tssPerformance, tssMoM },
  } = useContext(TssDataContext);

  if (tssPerformance == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  var input = tssPerformance;
  var formatInput = input / 5;
  var config: GaugeConfig = {
    axis: {
      label: {
        formatter: function formatter(v) {
          return Math.round((Number(v) / 2) * 10);
        },
      },
      subTickLine: { count: 3 },
    },
    percent: formatInput,
    statistic: {
      content: {
        style: {
          fontSize: '36px',
          lineHeight: '36px',
        },
        formatter: () => {
          return `${round(input, 1)}`;
        },
      },
    },
  };
  return (
    <div className="gauge-container">
      <Gauge
        {...config}
        height={300}
        range={{
          ticks: [0, GAUGE_GROWING, GAUGE_BUILDING, GAUGE_SOLID, GAUGE_STRONG, GAUGE_LEADING],
          color: [GROWING_COLOR, BUILDING_COLOR, SOLID_COLOR, STRONG_COLOR, LEADING_COLOR],
        }}
      />
      {tssMoM != null && (
        <div className="gauge-text">
          <span className={`kpi-badge ${tssMoM < 0 ? 'kpi-decrease' : 'kpi-increase'}`}>
            <span className="kpi-indicator">{tssMoM < 0 ? '▼' : '▲'}</span>
            <span className="kpi-value">{tssMoM}</span>
            <span className="kpi-unit">(MOM)</span>
          </span>
        </div>
      )}
    </div>
  );
};
