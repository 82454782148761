import { Column, ColumnConfig } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import { MeasureNameList } from '../types/healthy';
import { KPISearchTeamsResponse } from '../types/metrics';
import { getNpsColor } from '../utils/chartUtils';

interface IProps {
  data: KPISearchTeamsResponse[];
  measureData: MeasureNameList[];
}

export const KpiSearchChart = ({ data, measureData }: IProps) => {
  const [filterData, setFilterData] = useState(
    data?.map((item) => ({
      KpiName: item?.kpiName,
      value: item?.teamIds?.length,
    }))
  );

  useEffect(() => {
    setFilterData(
      data?.map((item) => ({
        KpiName: item?.kpiName,
        value: item?.teamIds?.length,
      }))
    );
  }, [data]);
  var config: ColumnConfig = {
    xField: 'KpiName',
    yField: 'value',
    xAxis: {
      label: {
        autoRotate: true,
        autoHide: false,
      },
    },
    color: ({ KpiName }) => {
      return measureData.find((item) => item.title === KpiName)?.color;
    },
    columnWidthRatio: 0.9,
    label: {},
    tooltip: {
      title: (record) => {
        return `Category: ${data.find((item) => item.kpiName === record)?.categoryName}`;
      },
      formatter: (data) => {
        return {
          name: `${data?.KpiName}`,
          value: data.value,
        };
      },
    },
    data: filterData,
    legend: {
      position: 'top',
      flipPage: false,
    },
  };
  return <Column {...config} height={600} style={{ marginLeft: '15px' }} />;
};
