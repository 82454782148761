import { Column } from '@ant-design/charts';
import { NpsDataContext } from '../contexts/NpsDataContext';
import { ColumnConfig } from '@ant-design/charts/es';
import { useContext } from 'react';
import { Empty } from 'antd';
import { getNpsCategory, getNpsColor } from '../utils/chartUtils';

type TeamType = 'Engagement' | 'Team';

interface IProps {
  type: TeamType;
}

export const EngagementNps = ({ type }: IProps) => {
  const { engagmentActuals, teamActuals } = useContext(NpsDataContext);

  const actualsNotNull =
    type === 'Engagement'
      ? engagmentActuals?.filter((item) => item.nps != null)
      : teamActuals?.filter((item) => item.nps != null);

  if (!actualsNotNull?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let npsDistribution = [];
  for (let i = 0; i <= 10; i++) {
    switch (i) {
      case 7:
        npsDistribution.push({
          nps: `${i}`,
          value: actualsNotNull.filter((element) => element.nps === i).length,
          category: getNpsCategory(i),
        });
        npsDistribution.push({
          nps: '>7-<8',
          value: actualsNotNull.filter((element) => element.nps > i && element.nps < i + 1).length,
          category: getNpsCategory(i),
        });
        break;
      case 8:
        npsDistribution.push({
          nps: `${i}`,
          value: actualsNotNull.filter((element) => element.nps === i).length,
          category: getNpsCategory(i - 1),
        });
        npsDistribution.push({
          nps: `>8`,
          value: actualsNotNull.filter((element) => element.nps > i && element.nps < i + 1).length,
          category: getNpsCategory(i + 1),
        });
        break;
      default:
        npsDistribution.push({
          nps: `${i}`,
          value: actualsNotNull.filter((element) => Math.floor(element.nps) === i).length,
          category: getNpsCategory(i),
        });
        break;
    }
  }

  var config: ColumnConfig = {
    seriesField: 'category',
    xField: 'nps',
    yField: 'value',
    xAxis: {
      label: {
        autoHide: false,
      },
    },
    color: ({ category }) => {
      const label = category.split(' (')[0];
      return getNpsColor(label);
    },
    columnWidthRatio: 0.9,
    label: {},
    tooltip: {
      formatter: (datum) => {
        switch (datum.nps) {
          case '>8':
            return {
              name: `${datum.category} (>8 - 8.9)`,
              value: datum.value,
            };

          default:
            return {
              name: `${datum.category} (${datum.nps})`,
              value: datum.value,
            };
        }
      },
    },
    data: npsDistribution,
    legend: {
      position: 'top',
      flipPage: false,
    },
  };

  return <Column {...config} height={300} />;
};
